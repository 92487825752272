@charset "utf-8";

@import "fonts";

$serif-font-family: "Lora", serif;
$sans-serif-font-family: "Source Sans 3", sans-serif;
$alternative-sans-serif-font-family: "Averia Sans Libre", sans-serif;
$monospace-font-family: "Fira Mono", Menlo, Consolas, Monaco, "Courier New", Courier, monospace;

$base-font-family: $sans-serif-font-family;
$headline-font-family: $alternative-sans-serif-font-family;
$title-font-family:  $serif-font-family;
$description-font-family: $serif-font-family;
$meta-font-family: $alternative-sans-serif-font-family;

@import "so-simple";